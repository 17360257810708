import { useEffect, useState } from 'react'

import { useIncidentCreationData } from '../hooks/useIncidentCreationData'
import { UseFormMethods } from 'react-hook-form'

import { Box, FormLabel } from '@chakra-ui/react'

import { AddressSearch, FormInputSelectControl } from '@/components/ui'
import { StyledFormInputControl } from '@/components/ui'
import { Facility } from '@/graphql/generated/schemas'
import {
  getDeviceOptions,
  getFloorOptions,
  getIncidentCategoryOptions,
  sortOptions,
} from '@/utils/forms/selectOptions'

import { FormInputsIProps } from '../types/types'
import { isDeviceHealthSelected } from '../utils/utils'

interface IncidentModalBodyIProps {
  form: UseFormMethods<FormInputsIProps>
  facility: Facility
}

export const NormalIncidentForm = ({
  form,
  facility,
}: IncidentModalBodyIProps) => {
  const { watch, control, errors, reset, getValues } = form
  const [floorId, setFloorId] = useState<string | null>(null)
  const [incidentCategoryId, setIncidentCategoryId] = useState<string | null>(
    null
  )

  const { floorsData, devicesData, categoriesData } = useIncidentCreationData(
    facility?.id,
    floorId
  )

  const incidentCategories = sortOptions(
    getIncidentCategoryOptions(categoriesData)
  )

  const floorOptions = getFloorOptions(floorsData)
  const deviceOptions = getDeviceOptions(devicesData)

  const watchIncidentCategoryId = watch('incidentCategory')
  const watchFloorOrigin = watch('floorOrigin')

  const isDeviceHealth = isDeviceHealthSelected(
    categoriesData,
    incidentCategoryId
  )

  useEffect(() => {
    if (watchIncidentCategoryId) {
      setIncidentCategoryId(watchIncidentCategoryId.value)
    }
  }, [watchIncidentCategoryId])

  useEffect(() => {
    if (watchFloorOrigin) {
      setFloorId(watchFloorOrigin.value)
      reset({
        ...getValues(),
        deviceOrigin: null,
      })
    }
  }, [watchFloorOrigin])

  return (
    <Box p={0}>
      <Box mb={3}>
        <FormInputSelectControl
          control={control}
          dataTestId='createIncidentModal_floorName'
          defaultValue={null}
          id='floorOrigin'
          label='Floor Name'
          noOptionsMessage={() =>
            !facility?.id ? 'Please select a facility first' : 'No floors'
          }
          options={floorOptions}
          placeholder='Select the Floor of Origin'
          rules={{ required: true }}
        />
      </Box>
      {facility?.address && (
        <StyledFormInputControl mb='4'>
          <FormLabel>Address</FormLabel>
          <AddressSearch
            defaultValue={facility?.address?.[0]}
            disabled={true}
            showTooltip={false}
          />
        </StyledFormInputControl>
      )}
      <Box mb={3}>
        <FormInputSelectControl
          control={control}
          dataTestId='createIncidentModal_incidentCategory'
          defaultValue={null}
          id='incidentCategory'
          label='Incident Category'
          options={incidentCategories}
          placeholder='Select Incident Category'
          rules={{ required: true }}
        />
      </Box>
      {isDeviceHealth && (
        <Box mb={3}>
          <FormInputSelectControl
            control={control}
            defaultValue={null}
            errorMessage={
              !!errors?.deviceOrigin &&
              'Device name is required when incident type is device health'
            }
            id='deviceOrigin'
            isInvalid={!!errors?.deviceOrigin}
            label='Device Name'
            options={deviceOptions}
            placeholder='Select the Device of Origin'
            rules={{
              required: isDeviceHealth,
            }}
          />
        </Box>
      )}
    </Box>
  )
}
